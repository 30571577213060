import { FC, ReactNode, useEffect } from 'react';
import {
  DehydratedState,
  HydrationBoundary,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  ColorPaletteDoc,
  FiltersDoc,
  FooterMenuDoc,
  MediaAssetsDoc,
  NavigationMenuDoc,
} from '@api/prismic/components/types';
import { AuthProvider } from '@lib/auth/AuthContext';
import { DrawerProvider } from '@lib/DrawerContext';
import { getEnv } from '@lib/env/getEnv';
import { TenantPropsProvider } from '@lib/tenants/TenantPropsContext';
import { Tenant, TenantTheme } from '@lib/tenants/types';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Language } from '@prismicio/client/types/ResolvedApi';
import Userfront from '@userfront/nextjs';

import { QUERY_CLIENT } from './fetch/constants';
import { ConditionalProvider } from './renderers/ConditionalProvider';
import { SubscriptionsProvider } from './subscriptions/SubscriptionsContext';
import { TOAST_TEST_ID } from './test/constants';
import { getTenantThemes, mapColorPaletteIntoTheme } from './theme/themeSetup';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface AppProvidersProps {
  isAuthenticated: boolean;
  children: ReactNode;
  cmsLanguages: Language[];
  navigationMenu: NavigationMenuDoc['data'] | null;
  footerMenu: FooterMenuDoc['data'] | null;
  filters: FiltersDoc['data'] | null;
  isTestEnv?: boolean;
  tenant?: Tenant;
  dehydratedState?: DehydratedState;
  colorPalette: ColorPaletteDoc['data'] | null;
  mediaAssets: MediaAssetsDoc['data'] | null;
}

export const AppProviders: FC<AppProvidersProps> = ({
  isAuthenticated,
  dehydratedState,
  children,
  tenant,
  cmsLanguages,
  navigationMenu,
  footerMenu,
  filters,
  colorPalette,
  mediaAssets,
  isTestEnv,
}) => {
  const newTenantTheme: TenantTheme = mapColorPaletteIntoTheme(
    colorPalette,
    tenant,
  );

  const theme = tenant ? getTenantThemes(newTenantTheme) : getTenantThemes();

  const env = getEnv();
  const authTenantId = tenant?.config.setup.authTenantId[env.client.appEnv];

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    // Remove the server-side injected CSS. (Part of Material-UI setup)
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  useEffect(() => {
    if (authTenantId) {
      // Auth provider init client-side
      Userfront.init(authTenantId);
    }
  }, [authTenantId]);

  if (tenant) {
    tenant.theme = newTenantTheme;
  }

  return (
    <ThemeProvider theme={theme}>
      <ConditionalProvider
        Provider={TenantPropsProvider}
        providerProps={{
          initTenant: tenant as Tenant,
          initCmsLanguages: cmsLanguages,
          initNavigationMenu: navigationMenu,
          initFooterMenu: footerMenu,
          initFilters: filters,
          initColorPalette: colorPalette,
          initMediaAssets: mediaAssets,
        }}
        condition={!!tenant}
      >
        <ConditionalProvider
          Provider={LocalizationProvider}
          providerProps={{
            dateAdapter: AdapterDateFns,
          }}
          condition={!!tenant}
        >
          <QueryClientProvider client={QUERY_CLIENT}>
            <ConditionalProvider
              Provider={AuthProvider}
              providerProps={{ authenticated: isAuthenticated }}
              condition={!!tenant}
            >
              {!isTestEnv && <ReactQueryDevtools />}
              <CssBaseline />
              <ConditionalProvider
                Provider={DrawerProvider}
                condition={!!tenant}
              >
                <ConditionalProvider
                  Provider={SubscriptionsProvider}
                  condition={!!tenant && !isTestEnv}
                >
                  <HydrationBoundary state={dehydratedState}>
                    {children}
                  </HydrationBoundary>
                  <ToastContainer
                    position="bottom-left"
                    autoClose={4500}
                    newestOnTop
                    draggable={false}
                    pauseOnHover={false}
                    data-testid={TOAST_TEST_ID}
                  />
                </ConditionalProvider>
              </ConditionalProvider>
            </ConditionalProvider>
          </QueryClientProvider>
        </ConditionalProvider>
      </ConditionalProvider>
    </ThemeProvider>
  );
};
