import { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { AppProviders } from '@lib/AppProviders';
import { verifyAuthority } from '@lib/auth/authorization';
import { getAccessData } from '@lib/auth/parser';
import {
  getAccessToken,
  makeAccessToken,
  removeAuthTokens,
} from '@lib/auth/token';
import { GoogleTagManager } from '@next/third-parties/google';
import { getEnv } from '@lib/env/getEnv';
import { cachedResponse } from '@lib/fetch/cache';
import { captureError } from '@lib/fetch/errors';
import { AugmentedRequest } from '@lib/fetch/types';
import { AvailableLocales } from '@lib/route/types';
import { getPreviewToken } from '@lib/slice-machine/preview';
import { isServer } from '@lib/storage/availability';
import { TENANT_CONFIG } from '@lib/tenants/config/constants';
import { tenantLocales } from '@lib/tenants/locales';
import { Tenant } from '@lib/tenants/types';
import { NextPageContext } from 'next';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { DEFAULT_LOCALE } from 'next-i18next.config';

import { api } from '@api/index';
import {
  ColorPaletteDoc,
  ColorPaletteRo,
  FiltersDoc,
  FiltersRo,
  FooterMenuDoc,
  FooterMenuRo,
  MediaAssetsDoc,
  MediaAssetsRo,
  NavigationMenuDoc,
  NavigationMenuRo,
} from '@api/prismic/components/types';
import { UserRole } from '@api/userfront/auth/types';
import { Language } from '@prismicio/client/types/ResolvedApi';

export interface CustomAppProps extends AppProps {
  isAuthenticated: boolean;
  cmsLanguages: Language[];
  navigationMenu: NavigationMenuDoc['data'] | null;
  footerMenu: FooterMenuDoc['data'] | null;
  filters: FiltersDoc['data'] | null;
  colorPalette: ColorPaletteDoc['data'] | null;
  mediaAssets: MediaAssetsDoc['data'] | null;
  tenant?: Tenant;
}

interface Composition {
  getInitialProps: (
    appContext: AppContext,
  ) => Promise<AppInitialProps & Partial<CustomAppProps>>;
}

const MyApp: FC<CustomAppProps> & Composition = ({
  Component,
  pageProps,
  isAuthenticated,
  tenant,
  cmsLanguages,
  navigationMenu,
  footerMenu,
  filters,
  colorPalette,
  mediaAssets,
}) => {
  const env = getEnv();
  const googleTagManagerId =
    tenant?.config.setup?.googleTagManagerId?.[env.client.appEnv];
  return (
    <>
      {googleTagManagerId && <GoogleTagManager gtmId={googleTagManagerId} />}
      <AppProviders
        isAuthenticated={isAuthenticated}
        dehydratedState={pageProps.dehydratedState}
        tenant={tenant}
        cmsLanguages={cmsLanguages}
        navigationMenu={navigationMenu}
        footerMenu={footerMenu}
        filters={filters}
        colorPalette={colorPalette}
        mediaAssets={mediaAssets}
      >
        <Script src="https://embedded.ryftpay.com/v1/ryft.min.js" />
        <Component {...pageProps} />
      </AppProviders>
    </>
  );
};

MyApp.getInitialProps = async (
  appContext: AppContext,
): Promise<AppInitialProps & Partial<CustomAppProps>> => {
  const { tenantName, cookies } = appContext.ctx.req as AugmentedRequest<true>;
  const tenant = tenantName ? TENANT_CONFIG[tenantName] : undefined;
  let navigationMenu: NavigationMenuDoc['data'] | null = null;
  let footerMenu: FooterMenuDoc['data'] | null = null;
  let filters: FiltersDoc['data'] | null = null;
  let colorPalette: ColorPaletteDoc['data'] | null = null;
  let mediaAssets: MediaAssetsDoc['data'] | null = null;
  let isAuthenticated = false;
  let userRoles: UserRole[] = [];
  let cmsLanguages: Language[] = [];
  const env = getEnv();
  const appProps = await App.getInitialProps(appContext);

  if (isServer && tenant) {
    const appLocale = appContext.router.locale as AvailableLocales;
    const validatedLocales = await tenantLocales(tenant, appLocale);
    cmsLanguages = validatedLocales.cmsLanguages;

    if (!validatedLocales.isLocaleCompatibleWithCms && cmsLanguages.length) {
      const localePrefix =
        cmsLanguages[0].id === DEFAULT_LOCALE ? '' : `/${cmsLanguages[0].id}`;

      serverRedirect(appContext.ctx, localePrefix + appContext.router.asPath);
    }

    if (tenant && validatedLocales.isLocaleCompatibleWithCms) {
      const appLocale = appContext.router.locale as AvailableLocales;
      const previewRef: string | undefined = getPreviewToken(cookies, tenant);
      let navigationMenuRes: NavigationMenuRo;
      let footerRes: FooterMenuRo;
      let filtersRes: FiltersRo;
      let colorPaletteRes: ColorPaletteRo;
      let mediaAssetsRes: MediaAssetsRo;
      const navigationMenuKey = `headerRes-${tenant.config.setup.name}-${appLocale}`;
      const footerKey = `footerRes-${tenant.config.setup.name}-${appLocale}`;
      const filtersKey = `filter-${tenant.config.setup.name}-${appLocale}`;
      const colorPaletteKey = `colorPalette-${tenant.config.setup.name}-${appLocale}`;
      const mediaAssetsKey = `mediaAssets-${tenant.config.setup.name}-${appLocale}`;
      const navigationMenuReq = () =>
        api.prismic.components.getHeaderMenu(tenant, appLocale, previewRef);
      const footerReq = () =>
        api.prismic.components.getFooterMenu(tenant, appLocale, previewRef);
      const filtersReq = () =>
        api.prismic.components.getFilters(tenant, appLocale, previewRef);
      const colorPaletteReq = () =>
        api.prismic.components.getColorPalette(tenant, appLocale, previewRef);
      const mediaAssetsReq = () =>
        api.prismic.components.getMediaAssets(tenant, appLocale, previewRef);

      try {
        navigationMenuRes = await cachedResponse(
          navigationMenuKey,
          navigationMenuReq,
          previewRef,
        );
        footerRes = await cachedResponse(footerKey, footerReq, previewRef);
        filtersRes = await cachedResponse(filtersKey, filtersReq, previewRef);
        colorPaletteRes = await cachedResponse(
          colorPaletteKey,
          colorPaletteReq,
          previewRef,
        );
        mediaAssetsRes = await cachedResponse(
          mediaAssetsKey,
          mediaAssetsReq,
          previewRef,
        );
      } catch (err) {
        captureError(`CMS error while fetching header/footer`, err);
      }

      colorPalette = colorPaletteRes?.data ?? null;
      navigationMenu = navigationMenuRes?.data ?? null;
      footerMenu = footerRes?.data ?? null;
      filters = filtersRes?.data ?? null;
      mediaAssets = mediaAssetsRes?.data ?? null;
    }

    const authTenantId = tenant.config.setup.authTenantId[env.client.appEnv];
    const accessToken = tenantName && getAccessToken(tenantName, cookies);

    if (authTenantId && accessToken) {
      try {
        const verifyTokenRes = await api.internal.auth.postVerifyToken({
          accessToken,
          tenantName: tenant.config.setup.name,
        });
        isAuthenticated = verifyTokenRes.isAuthenticated;
        userRoles = verifyTokenRes.roles;
      } catch (err) {
        removeAuthTokens(authTenantId, appContext.ctx.res);
        captureError(
          `Internal error while verifying token for tenant: ${tenantName}`,
          err,
        );
      }
    }
  } else if (!isServer && tenant) {
    const authTenantId = tenant.config.setup.authTenantId[env.client.appEnv];

    if (authTenantId) {
      const accessToken = cookies[makeAccessToken(authTenantId)];
      const accessData = await getAccessData(accessToken);
      const tenantId = accessData?.tenantId;
      isAuthenticated = !!accessData;
      userRoles =
        (tenantId && accessData?.authorization?.[tenantId]?.roles) || [];
    }
  }

  const { isAuthorized, redirectTo } = verifyAuthority(
    tenant,
    isAuthenticated,
    appContext.router.asPath,
    appContext.router.query,
    userRoles,
  );

  if (!isAuthorized && redirectTo) {
    serverRedirect(appContext.ctx, redirectTo);
  }

  return {
    ...appProps,
    isAuthenticated,
    tenant,
    cmsLanguages,
    navigationMenu,
    footerMenu,
    filters,
    colorPalette,
    mediaAssets,
  };
};

const serverRedirect = (ctx: NextPageContext, redirectTo: string): void => {
  if (ctx.res) {
    ctx.res.setHeader('Location', redirectTo);
    ctx.res.statusCode = 301;
  }
};

export default appWithTranslation(MyApp as FC<AppProps | CustomAppProps>);
