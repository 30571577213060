import { DefaultHeaders } from './types';

export const getDefaultHeaders = (
  cmsSubdomain: string,
  token: string
): DefaultHeaders => {
  const headers: DefaultHeaders = {
    repository: cmsSubdomain,
    Authorization: `Bearer ${token}`,
  };

  return headers;
};
