import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  AddOrderOffersRequest,
  ChangeInventoryOrderDto,
  GetOrderRo,
  GetOrderRouteParams,
  OrderPaymentReq,
  OrderType,
  PortIn,
  PostSubmitOrderDto,
  PostSubmitOrderRo,
  PostSubmitTopUpDto,
  PostSubmitTopUpRo,
  Resource,
} from './types';

/**
 * @deprecated
 */
export const postSubmitOrder = async (
  dto: PostSubmitOrderDto,
  tenant: Tenant,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(API_URLS.order.submit, dto, {
    baseURL: getBaseUrl(tenant),
  });

  return res.data;
};

export const getOrder = async (
  routeParams: GetOrderRouteParams,
  tenant: Tenant,
): Promise<GetOrderRo> => {
  const res = await client.get<GetOrderRo>(
    replaceRouteParams(API_URLS.order.order, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const postSubmitAuthTopUp = async (
  dto: PostSubmitTopUpDto,
  tenant: Tenant,
): Promise<PostSubmitTopUpRo> => {
  const res = await client.post<PostSubmitTopUpRo>(
    API_URLS.order.submitTopUp,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const createNewOrder = async (
  tenant: Tenant,
  orderType: OrderType,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.createNewOrder,
    { orderType },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addNewOrderOffers = async (
  tenant: Tenant,
  { orderId, offers }: AddOrderOffersRequest,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.addNewOrderOffers,
    { offers, orderId },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addPortInDataToOrder = async (
  tenant: Tenant,
  orderId: string,
  orderProductId: string,
  portIn: PortIn,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.addPortInDataToOrder,
    { orderId, orderProductId, portIn },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addPaymentDataToOrder = async (
  tenant: Tenant,
  orderId: string,
  payment: OrderPaymentReq,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.addPaymentDataToOrder,
    { orderId, payment },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const submitOrder = async (
  tenant: Tenant,
  id: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.submit,
    { orderId: id },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addNewOrderResources = async (
  tenant: Tenant,
  orderId: string,
  resources: Resource[],
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.order.addNewOrderResources, {
      orderId,
    }),
    { resources },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const removeOrderResources = async (
  tenant: Tenant,
  orderId: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.order.removeOrderResources, {
      orderId,
    }),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};

export const changeInventoryOrder = async (
  tenant: Tenant,
  params: ChangeInventoryOrderDto,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.order.changeInventoryOrder,
    params,
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};

export const setSubscriptionForModificationAndProductsToBeTerminated = async (
  tenant: Tenant,
  orderId: string,
  subscriptionId: string,
  orderProductIds: string[],
  terminationDate: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(
      API_URLS.order.setSubscriptionForModificationAndProductsToBeTerminated,
      { orderId },
    ),
    { subscriptionId, orderProductIds, terminationDate },
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};
