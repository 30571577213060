import { AnyCustomType } from '@lib/custom-types/types';
import { replaceRouteParams } from '@lib/route/routeParams';

import { client } from '../client';
import { getDefaultHeaders } from '../config';
import { API_URLS } from '../constants';
import { AnyCustomTypeRo, AnyCustomTypesRo } from './types';

export const getAll = async (
  cmsSubdomain: string,
  token: string
): Promise<AnyCustomTypesRo> => {
  const res = await client.get<AnyCustomTypesRo>(API_URLS.customTypes.index, {
    headers: getDefaultHeaders(cmsSubdomain, token),
  });

  return res.data;
};

export const getSingle = async (
  cmsSubdomain: string,
  token: string,
  id: string
): Promise<AnyCustomTypeRo> => {
  const res = await client.get<AnyCustomTypeRo>(
    replaceRouteParams(API_URLS.customTypes.customType, { id }),
    {
      headers: getDefaultHeaders(cmsSubdomain, token),
    }
  );

  return res.data;
};

export const insertSingle = async (
  cmsSubdomain: string,
  token: string,
  slice: AnyCustomType
): Promise<AnyCustomTypeRo> => {
  const res = await client.post<AnyCustomTypeRo>(
    API_URLS.customTypes.insert,
    slice,
    {
      headers: {
        ...getDefaultHeaders(cmsSubdomain, token),
        'Content-Type': 'text/plain',
      },
    }
  );

  return res.data;
};

export const updateSingle = async (
  cmsSubdomain: string,
  token: string,
  slice: AnyCustomType
): Promise<AnyCustomTypeRo> => {
  const res = await client.post<AnyCustomTypeRo>(
    API_URLS.customTypes.update,
    slice,
    {
      headers: {
        ...getDefaultHeaders(cmsSubdomain, token),
        'Content-Type': 'text/plain',
      },
    }
  );

  return res.data;
};

export const deleteSingle = async (
  cmsSubdomain: string,
  token: string,
  id: string
): Promise<AnyCustomTypeRo> => {
  const res = await client.delete<AnyCustomTypeRo>(
    replaceRouteParams(API_URLS.customTypes.customType, { id }),
    {
      headers: getDefaultHeaders(cmsSubdomain, token),
    }
  );

  return res.data;
};
