import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { api } from '@api/index';
import {
  GetSubscriptionsRo,
  Subscription,
} from '@api/ocb-digital/subscription/types';

import { useAuth } from '../auth/AuthContext';
import { CACHE_KEYS } from '../fetch/constants';
import { useApiQuery } from '../fetch/useApiQuery';
import { SESSION_STORAGE } from '../storage/constants';
import { useStorage } from '../storage/useStorage';
import { useTenantProps } from '../tenants/TenantPropsContext';

export const SubscriptionsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const { tenant } = useTenantProps();
  const { data, isLoading, refetch, isError, isFetching } = useApiQuery(
    fetchSubscriptions,
    { queryKey: [CACHE_KEYS.subscriptions] },
  );

  const [storedSubscriptionId, setStoredSubscriptionId] = useStorage<
    string | null
  >('session', SESSION_STORAGE.SELECTED_SUBSCRIPTION_ID, null);

  const handleSelectSubscription = useCallback(
    (selectedId: string) => {
      setStoredSubscriptionId(selectedId);
    },
    [setStoredSubscriptionId],
  );

  const handleSubscriptionFetchSuccess = useCallback(
    (subscriptions: GetSubscriptionsRo | undefined) => {
      if (!subscriptions || !subscriptions.length) {
        return;
      }

      const storedSubscriptionExists = subscriptions.some(
        ({ id }) => id === storedSubscriptionId,
      );
      const isParentPending = subscriptions[0].products[0].status === 'PENDING';
      const isSubscriptionPending = subscriptions[0].status === 'PENDING';

      if (
        !storedSubscriptionExists &&
        !(isParentPending || isSubscriptionPending)
      ) {
        handleSelectSubscription(subscriptions[0].id);
      }
    },
    [handleSelectSubscription, storedSubscriptionId],
  );

  useEffect(() => {
    handleSubscriptionFetchSuccess(data);
  }, [data, handleSubscriptionFetchSuccess]);

  useEffect(() => {
    refetch();
  }, [isAuthenticated, refetch]);

  return (
    <SubscriptionsContext.Provider
      value={{
        subscriptions: data ?? [],
        isLoading,
        isError,
        isFetching,
        selectSubscription: handleSelectSubscription,
        selectedSubscription:
          data?.find(({ id }) => id === storedSubscriptionId) ?? null,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );

  function fetchSubscriptions(): Promise<GetSubscriptionsRo> | undefined {
    if (isAuthenticated) {
      return api.ocbDigital.subscription.getSubscriptions(tenant);
    }
  }
};

export interface SubscriptionsContextProps {
  subscriptions: Subscription[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  selectSubscription: (id: string) => void;
  selectedSubscription: Subscription | null;
}

const SubscriptionsContext = createContext<
  SubscriptionsContextProps | undefined
>(undefined);

export const useSubscriptions = (): SubscriptionsContextProps => {
  const context = useContext(SubscriptionsContext);

  if (context === undefined) {
    throw new Error(
      'useSubscriptions must be used within an SubscriptionsProvider',
    );
  }

  return context;
};
