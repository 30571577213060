import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { GetCustomerRo, PostUpdateCustomerRo } from './types';

export const createCustomerUser = async (
  idToken: string,
  tenant: Tenant
): Promise<unknown> => {
  const res = await client.post(API_URLS.customer.register, undefined, {
    baseURL: getBaseUrl(tenant),
    headers: {
      idToken: idToken,
    },
  });

  return res.data;
};

export const getCustomer = async (tenant: Tenant): Promise<GetCustomerRo> => {
  const { data } = await client.get<GetCustomerRo>(API_URLS.customer.index, {
    baseURL: getBaseUrl(tenant),
  });

  return data;
};

export const updateCustomer = async (
  tenant: Tenant,
  params: PostUpdateCustomerRo
): Promise<GetCustomerRo> => {
  const { data } = await client.post<GetCustomerRo>(
    API_URLS.order.submitCustomerDataUpdate,
    params,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};
