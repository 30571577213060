import axios, { AxiosRequestConfig } from 'axios';

import { stringify } from '@lib/route/queryParams';
import Userfront from '@userfront/nextjs';

import { getBaseUrl } from './config';

export const client = axios.create({
  paramsSerializer: (params) => stringify(params),
});

client.interceptors.request.use(mutateRequestConfig, (error) => {
  Promise.reject(error);
});

function mutateRequestConfig(config: AxiosRequestConfig): AxiosRequestConfig {
  const accessToken = Userfront.accessToken();

  config.baseURL = getBaseUrl();

  if (!config.headers) {
    config.headers = {};
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
}
