import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  GetOrderRo,
  GetOrderRouteParams,
  NewCustomerForm,
  PostSubmitOrderDto,
  PostSubmitOrderRo,
  PostSubmitTopUpDto,
  PostSubmitTopUpRo,
  ValidateEmailVerificationParams,
} from './types';
import { Offer, OrderPaymentReq, PortIn, Resource } from '../order/types';

export const getOrder = async (
  routeParams: GetOrderRouteParams,
  tenant: Tenant,
): Promise<GetOrderRo> => {
  const res = await client.get<GetOrderRo>(
    replaceRouteParams(API_URLS.orderAuthless.order, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const postSubmitTopUp = async (
  dto: PostSubmitTopUpDto,
  tenant: Tenant,
): Promise<PostSubmitTopUpRo> => {
  const res = await client.post<PostSubmitTopUpRo>(
    API_URLS.orderAuthless.submitTopUp,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addNewCustomerToOrder = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  customerForm: NewCustomerForm,
  files: Record<'identityFile' | 'proofOfAddressFile', File | undefined>,
): Promise<PostSubmitOrderRo> => {
  const formData = new FormData();
  formData.append(
    'req',
    new Blob([JSON.stringify({ tenantId, orderId, customerForm })], {
      type: 'application/json',
    }),
  );

  if (files?.identityFile) formData.append('files', files.identityFile);
  if (files?.proofOfAddressFile)
    formData.append('files', files.proofOfAddressFile);

  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.addNewCustomerToOrder,
    formData,
    {
      baseURL: getBaseUrl(tenant),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return res.data;
};

export const addNewCustomerToOrderNoFiles = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  customerForm: NewCustomerForm,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.addNewCustomerToOrderNoFiles,
    { tenantId, orderId, customerForm },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const submitOrder = async (
  tenant: Tenant,
  id: string,
  tenantId: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.submit,
    { orderId: id, tenantId },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addPaymentDataToOrder = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  payment: OrderPaymentReq,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.addPaymentDataToOrder,
    { tenantId, orderId, payment },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

/**
 * @deprecated Since new order flow was implemented
 */
export const postSubmitOrder = async (
  dto: PostSubmitOrderDto,
  tenant: Tenant,
): Promise<PostSubmitOrderRo> => {
  const formData = new FormData();
  formData.append(
    'form',
    new Blob([JSON.stringify(dto.form)], { type: 'application/json' }),
  );
  formData.append('files', dto.files[0]);
  formData.append('files', dto.files[1]);

  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.submit,
    formData,
    {
      baseURL: getBaseUrl(tenant),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return res.data;
};

export const createNewAuthlessOrder = async (
  tenant: Tenant,
  tenantId: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.createNewOrder,
    { tenantId },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addNewOrderOffers = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  offers: Offer[],
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.addNewOrderOffers,
    { tenantId, orderId, offers },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addPortInDataToOrder = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  orderProductId: string,
  portIn: PortIn,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    API_URLS.orderAuthless.addPortInDataToOrder,
    { tenantId, orderId, orderProductId, portIn },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const addNewOrderResources = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
  resources: Resource[],
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.orderAuthless.addNewOrderResources, {
      orderId,
    }),
    { tenantId, resources },
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const removeOrderResources = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.orderAuthless.removeOrderResources, {
      orderId,
      tenantId,
    }),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};

export const validateEmailVerificationCode = async ({
  tenant,
  tenantId,
  orderId,
  verificationCode,
}: ValidateEmailVerificationParams): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.orderAuthless.validateVerificationCode, {
      orderId,
      tenantId,
      verificationCode,
    }),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const sendVerificationCodeByEmail = async (
  tenant: Tenant,
  tenantId: string,
  orderId: string,
): Promise<PostSubmitOrderRo> => {
  const res = await client.post<PostSubmitOrderRo>(
    replaceRouteParams(API_URLS.orderAuthless.sendVerificationCodeByEmail, {
      orderId,
      tenantId,
    }),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};
