export const API_URLS = {
  prismic: {
    preview: '/api/prismic/preview',
    exitPreview: '/api/prismic/exit-preview',
    slices: '/api/prismic/slices',
    slice: '/api/prismic/slices/:id',
    customTypes: '/api/prismic/custom-types',
    customType: '/api/prismic/custom-types/:id',
  },
  auth: {
    verifyToken: `/api/auth/verify-token`,
  },
  clearCache: {
    index: '/api/clear-cache',
  },
} as const;
